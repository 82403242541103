// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/YouSheBiaoTiHei.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/SourceHanSerifCN-Regular-1.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,html{margin:0;padding:0;height:100%;width:100%}div,i,li,p,span,table,td,th,ul{box-sizing:border-box}@font-face{font-family:YouSheBiaoTiHei;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:SYST;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.roll::-webkit-scrollbar{width:4px;height:2px;opacity:1}.roll::-webkit-scrollbar-thumb{background-color:#b8b8b8}.roll::-webkit-scrollbar-track{background:#f5f7fa}.esri-features__content-feature{width:350px!important}.esri-popup__main-container{max-height:700px!important}.esri-ui .esri-popup{width:350px!important;overflow:hidden}.esri-widget__table tr:hover{background-color:#b6d8ff}.esri-feature-fields__field-header{width:100px!important;display:inline-block;padding:4px!important;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;cursor:default}.esri-feature-fields__field-header:hover{white-space:wrap}.esri-popup{background-color:#fff}.esri-feature-fields__field-data{cursor:default;display:inline-block;width:calc(100% - 100px)!important;padding:4px!important}.esri-features__container{padding:0}.esri-popup__button.esri-popup__button--zoom{display:none!important}.zhifaBox{border-collapse:collapse;color:#323232;width:100%}.zhifaBox tr{background-color:#ededed;cursor:default}.zhifaBox tr:nth-child(2n){background-color:#fbfbfb}.zhifaBox tr:hover{background-color:#b6d8ff}.zhifaBox td{vertical-align:top;word-break:break-word;padding:.5em .7em;font-size:12px;font-weight:400;border:1px solid #eee}.zhifaBox td:first-child{display:inline-block;width:140px!important}.zhifaBox td:first-child div{width:140px!important}.zhifaBox td:last-child{width:100%}.zhifaBox td .aqiColor{width:26px;height:14px;margin-right:4px;border-radius:4px}.zhifaBox .flexBox{width:100%;display:flex;align-items:center}.dtBox{margin:6px 0;font-size:14px;font-weight:700;text-align:center}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
