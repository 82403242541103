import Mock from 'mockjs'
// 设置响应延时
Mock.setup({
    // 可以是整数，也可以是‘-’字符串
    timeout: '200-600'
})

// 引入随机函数
import { Random } from 'mockjs'

Mock.mock("/mock/video", (data) => {
    return {
        "success": true,
        "status": 200,
        "msg": "操作成功！",
        "data": [
            {
                id: "v1",
                title: "视频点1",
                latitude: 27.701589,
                longitude: 108.862273,
                videoUrl: "./static/testvideo.mp4",
            }, {
                id: "v2",
                title: "视频点2",
                latitude: 27.702931,
                longitude: 108.865649,
                videoUrl: "./static/testvideo.mp4",
            }, {
                id: "v3",
                title: "视频点3",
                latitude: 27.699638,
                longitude: 108.871371,
                videoUrl: "",
            }, {
                id: "v4",
                title: "视频点4",
                latitude: 27.695636,
                longitude: 108.878380,
                videoUrl: "",
            }, {
                id: "v5",
                title: "视频点5",
                latitude: 27.699309,
                longitude: 108.883044,
                videoUrl: "./static/testvideo.mp4",
            },

        ]
    }
})


Mock.mock("/mock/patrol", (data) => {
    return {
        "success": true,
        "status": 200,
        "msg": "操作成功！",
        "data": [
            {
                id: "pv1",
                title: "巡护点1",
                latitude: 27.701589,
                longitude: 108.865273,
                state: true
            }, {
                id: "pv2",
                title: "巡护点2",
                latitude: 27.702131,
                longitude: 108.8225649,
                state: true

            }, {
                id: "pv3",
                title: "巡护点3",
                latitude: 27.699618,
                longitude: 108.821371,
                state: false

            }, {
                id: "pv4",
                title: "巡护点4",
                latitude: 27.692636,
                longitude: 108.878180,
                state: false

            }, {
                id: "pv5",
                title: "巡护点5",
                latitude: 27.694309,
                longitude: 108.883444,
                state: false

            },

        ]
    }
})


Mock.mock("/mock/wrjList", (data) => {
    return {
        "success": true,
        "status": 200,
        "msg": "操作成功！",
        "data": [
            {
                id: "p1",
                name: "XXXXxxxxx",
                state: "进行中",
                latitude: 27.702589,
                longitude: 108.852273,
                online: true,
            },
            {
                id: "p2",
                name: "XXXX",
                state: "进行中",
                latitude: 27.712931,
                longitude: 108.862649,
                online: true,
            },
            {
                id: "p3",
                name: "XXXX",
                state: "进行中",
                latitude: 27.699658,
                longitude: 108.871171,
                online: false,
            },
            {
                id: "p4",
                name: "XXXX",
                state: "进行中",
                latitude: 27.659658,
                longitude: 108.871171,
                online: false,
            },
        ]
    }
})