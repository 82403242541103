import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
// 路由重写
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    // call , apply 都可以调用函数一次，可篡改函数上下文一次
    // call传参用逗号隔开，apply传参用数组
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(this, location, () => { }, () => { });
  }
};
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(this, location, () => { }, () => { });
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: "/homeview",
  },

  {
    path: '/homeview',
    name: 'HomeView',
    component: () => import('../views/YZmap/HomeView'),
    redirect: "/yzmap",
    children: [{
      path: '/yzmap',
      name: 'YZmap',
      component: () => import('../views/YZmap'),
    },]
  },
  // {
  //   path: '/yzmap',
  //   name: 'YZmap',
  //   component: () => import('../views/YZmap'),
  // },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test')
  },


]

const router = new VueRouter({
  routes
})

export default router
