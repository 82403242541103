import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
// 全局 Loading 方法
const loadingInstance = {}

function startLoading() {
    // 使用Element UI的Loading组件
    loadingInstance.fullscreen = Element.Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.7)'
    });
}

function endLoading() {
    // 关闭全屏 Loading
    loadingInstance.fullscreen && loadingInstance.fullscreen.close();
}

// 在Vue的原型上添加全局 Loading 方法
Vue.prototype.$startLoading = startLoading;
Vue.prototype.$endLoading = endLoading;

// 在Vue实例化之前开启Loading
// startLoading();
