import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/style.scss' //全局样式
import './assets/css/animate.min.css' //动画css
import common from './assets/js/common.js' //引入公共方法
import http from './assets/js/http.js' //封装axios
import './mock.js'
import axios from 'axios';

axios.defaults.timeout = 20000 // 设置默认的超时时间，单位是毫秒

// Vue.prototype.http = http; //封装axios
Vue.prototype.common = common; //引入公共方法

Vue.config.productionTip = false
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import 'echarts-gl';
// 根据所使用的组件依赖需求添加相应的第三方库, 参考具体组件,如天际线分析需要如下:
// import echarts from 'echarts'
window.echarts = echarts //挂载到window上
new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: h => h(App)
}).$mount('#app')
