// import { commomLogin, commomMenu, commomTree } from '@/api/commonUser'
// *******************  弃用 *******************
// *******************  弃用 *******************
// *******************  弃用 *******************
// *******************  弃用 *******************
// import router, { resetRouter } from '@/router'

const state = {
    accesstoken: "", //token
    commonUser: "",
    commonMenu: "",
    commonTree: ""
}

const mutations = {
    SET_ACCESSTOKEN: (state, access_token) => {
        state.accesstoken = access_token
    },
    SET_COMMONUSER: (state, data) => {
        state.commonUser = data
    },
    SET_MENU: (state, data) => {
        state.commonMenu = data
    },
    SET_TREE: (state, data) => {
        state.commonTree = data
    },
}

const actions = {
    // login({ commit }, userInfo) {
    //     return new Promise((resolve, reject) => {
    //         commomLogin(userInfo).then(response => {
    //             const { data } = response
    //             commit('SET_ACCESSTOKEN', data.access_token);
    //             commit('SET_COMMONUSER', data)
    //             resolve(data)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
